import $$ from 'cmn-utils';
import modelEnhance from '@/utils/modelEnhance';
import React from "react";
import {router} from "dva";
const { Redirect } = router;
import cookie from '@/utils/cookie';
export default modelEnhance({
  namespace: 'global',

  state: {
    menu: [],
    flatMenu: [],
    badge:{
      total: 0,
      add: 0,
      del: 0
    },
    monitor: {

    },
    openKeys:[],
  },
  subscriptions: {
    setup({ dispatch, history }) {
      history.listen(({ pathname }) => {
        const user = cookie.load();
        if(!user){
          return <Redirect to='/sign/login' />
        }else{
          if(user.accountType === 2){
            dispatch({
              type: 'getDealModalCount'
            });
          }
        }
      });
    }
  },
  effects: {
    *getMenu({ payload }, { call, put }) {
      const res = yield call(getMenu, payload);
      if(res){
        const { status,data,openKeys } = res;
        if (status) {
          loadMenu(data);
          yield put({
            type: 'getMenuSuccess',
            payload: {data:data,openKeys:openKeys},
          });
        }
      }
    },
    *getDealModalCount({ payload }, { select, put }){
      // const { badge } = yield select(state => state.global);
      yield put.resolve({
        type: '@request',
        payload: {
          method:'POST',
          url: '/admin/getDealModalCount',
          valueField: 'badge',
        },
        afterResponse: response=>{
          const {badge} = response;
          if(badge){
            return badge;
          }
          return {total: 0,add: 0,del: 0};
        },
      });
    },
    *logout({ payload }, {  put }){
      yield put({
        type: 'clearGlobalData',
        payload: payload,
      });
    }
  },

  reducers: {
    getMenuSuccess(state, { payload }) {
      return {
        ...state,
        menu: payload.data,
        openKeys: payload.openKeys,
        flatMenu: getFlatMenu(payload.data),
      };
    },
    clearGlobalData(state,{payload}){
      return {
        menu: [],
        flatMenu: [],
        badge:{
          total: 0,
          add: 0,
          del: 0
        },
        monitor: {},
        openKeys:[],
      }
    }
  },
});

export function getFlatMenu(menus) {
  let menu = [];
  menus.forEach(item => {
    if (item.children) {
      menu = menu.concat(getFlatMenu(item.children));
    }
    menu.push(item);
  });
  return menu;
}

export async function getTestMenu(payload) {
  return $$.post('/test/menu', payload);
}

export async function getUserMenu(payload) {
  return $$.post('/user/menu', payload);
}

export async function getCustomerMenu(payload) {
  return $$.post('/customer/menu', payload);
}

export async function getMenu(payload) {
  return $$.post('/admin/getMenu', payload,{headers: {'token': $$.getStore("user")?$$.getStore("user").token:null,'content-type': 'application/x-www-form-urlencoded'}});
}
export function loadMenu(data){
  const loopMenu = (menu, pitem = {}) => {
    menu.forEach(item => {
      if(pitem.path)
      if (pitem.path) {
        item.parentPath = pitem.parentPath ? pitem.parentPath.concat(pitem.path) : [pitem.path];
      }
      if (item.children && item.children.length) {
        loopMenu(item.children, item);
      }
    });
  };
  return loopMenu(data);
}

