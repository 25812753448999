import React,{Fragment} from 'react';
import { connect, router, routerRedux } from 'dva';
import {Alert, Layout,Button} from '@wii-fe/wau';
import NavBar from 'components/NavBar';
import { LeftSideBar, RightSideBar } from 'components/SideBar';
import TopBar from 'components/TopBar';
import pathToRegexp from 'path-to-regexp';
import { enquireIsMobile } from '@/utils/enquireScreen';
import TabsLayout from './TabsLayout';
import $$ from 'cmn-utils';
import cookie from '@/utils/cookie'
import cx from 'classnames';
import isEqual from 'react-fast-compare';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import 'assets/styles/transition.less';
import './styles/basic.less';
const { Switch } = router;
const { Content, Header } = Layout;

/**
 * 基本部局
 * 可设置多种皮肤 theme: [light, grey, primary, info, warning, danger, alert, system, success, dark]
 * 可设置多种布局 [header(固定头), sidebar(固定边栏), breadcrumb(固定面包蟹), tabLayout(标签布局)]
 * @author weiq
 */
@connect(({ global }) => ({ global }))
export default class BasicLayout extends React.PureComponent {
  constructor(props) {
    super(props);
    const user = cookie.load();
    const theme = $$.getStore('theme', {
      leftSide: 'light', // 左边
      navbar: 'primary' // 顶部
    });
    if (!theme.layout) {
      theme.layout = [
        'fixedHeader',
        'fixedSidebar',
        'fixedBreadcrumbs'
        // 'hidedBreadcrumbs',
        // 'tabLayout',
      ];
    }
    this.state = {
      collapsedLeftSide: false, // 左边栏开关控制
      leftCollapsedWidth: 60, // 左边栏宽度
      expandTopBar: false, // 头部多功能区开合
      showSidebarHeader: false, // 左边栏头部开关
      collapsedRightSide: true, // 右边栏开关
      theme, // 皮肤设置
      currentMenu: {},
      isMobile: false,
      user
    };

    props.dispatch({
      type: 'global/getMenu'
    });
  }

  componentDidMount() {
    this.checkLoginState();
    this.unregisterEnquire = enquireIsMobile(ismobile => {
      const { isMobile, theme } = this.state;
      if (isMobile !== ismobile) {
        // 如查是移动端则不固定侧边栏
        if (ismobile && $$.isArray(theme.layout)) {
          theme.layout = theme.layout.filter(item => item !== 'fixedSidebar');
        }
        this.setState({
          isMobile: ismobile
        });
      }
    });
  }

  // 检查有户是否登录
  checkLoginState() {
    const user = cookie.load();
    if (!user) {
      this.props.dispatch(routerRedux.replace('/sign/login'));
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !isEqual(this.props.location.pathname, prevProps.location.pathname) ||
      !isEqual(this.props.global.flatMenu, prevProps.global.flatMenu)
    ) {
      this.setState({
        currentMenu: this.getCurrentMenu(this.props) || {}
      });
    }
  }

  componentWillUnmount() {
    // 清理监听
    this.unregisterEnquire();
  }

  getCurrentMenu(props) {
    const {
      location: { pathname },
      global
    } = props || this.props;
    return this.getMeunMatchKeys(global.flatMenu, pathname)[0];
  }

  getMeunMatchKeys = (flatMenu, path) => {
    return flatMenu.filter(item => {
      return pathToRegexp(item.path).test(path);
    });
  };

  /**
   * 顶部左侧菜单图标收缩控制
   */
  onCollapseLeftSide = _ => {
    const collapsedLeftSide =
      this.state.leftCollapsedWidth === 0
        ? true
        : !this.state.collapsedLeftSide;
    const collapsedRightSide =
      this.state.collapsedRightSide || !collapsedLeftSide;

    this.setState({
      collapsedLeftSide,
      collapsedRightSide,
      leftCollapsedWidth: 60
    });
  };

  /**
   * 完全关闭左边栏，即宽为0
   */
  onCollapseLeftSideAll = _ => {
    this.setState({
      collapsedLeftSide: true,
      leftCollapsedWidth: 0
    });
  };

  /**
   * 展开面包屑所在条中的多功能区
   */
  onExpandTopBar = _ => {
    this.setState({
      expandTopBar: true
    });
  };

  /**
   * 与上面相反
   */
  onCollapseTopBar = _ => {
    this.setState({
      expandTopBar: false
    });
  };

  /**
   * 切换左边栏中头部的开合
   */
  toggleSidebarHeader = _ => {
    this.setState({
      showSidebarHeader: !this.state.showSidebarHeader
    });
  };

  /**
   * 切换右边栏
   */
  toggleRightSide = _ => {
    const { collapsedLeftSide, collapsedRightSide } = this.state;
    this.setState({
      collapsedLeftSide: collapsedRightSide ? true : collapsedLeftSide,
      collapsedRightSide: !collapsedRightSide
    });
  };

  onChangeTheme = theme => {
    $$.setStore('theme', theme);
    this.setState({
      theme
    });
  };
  goToApiDoc = () =>{
    window.open('https://facefusion-api.huanlian.cn/face-api/doc.html');
    // fetch('https://facefusion-api.h6app.com/face-api/doc.html', {
    //   method: 'GET',
    //   headers: new Headers({
    //     //自己加的头信息全都要转成string
    //     'X-TOKEN': $$.getStore('token'),
    //   }),
    // })
    //     .then(res =>
    //       res.blob()
    //     )
    //     .then(data => {
    //       const blobUrl = window.URL.createObjectURL(data);
    //       window.open(blobUrl, "_blank").focus();
    //     }).catch((err) => {
    //       console.log(err);
    //     });
    // fetch('http://localhost:8091/face-api/doc.html').
    // window.open('http://localhost:8091/face-api/doc.html')
    // let myWindow=window.open('','','width=640,height=860');
    // myWindow.document.write('<p>新页面</p>')
    // myWindow.focus();
  };

  /**
   * 退出登陆
   * @returns {JSX.Element}
   */
  logout = history =>{
    cookie.remove();
    this.props.dispatch({
      type: 'global/logout'
    });
    history.replace('/sign/login');
  };
  render() {
    const {
      collapsedLeftSide,
      leftCollapsedWidth,
      expandTopBar,
      showSidebarHeader,
      collapsedRightSide,
      theme,
      user,
      currentMenu,
      isMobile,
    } = this.state;
    const { routerData, location, global,history } = this.props;
    const { menu, flatMenu, badge,openKeys } = global;
    const { childRoutes } = routerData;
    const classnames = cx('basic-layout', 'full-layout', {
      fixed: theme.layout && theme.layout.indexOf('fixedSidebar') !== -1,
      'fixed-header':
        theme.layout && theme.layout.indexOf('fixedHeader') !== -1,
      'fixed-breadcrumbs':
        theme.layout && theme.layout.indexOf('fixedBreadcrumbs') !== -1,
      'hided-breadcrumbs':
        theme.layout && theme.layout.indexOf('hidedBreadcrumbs') !== -1
    });
    let showTopAlert = user&&user.accountType === 0;
    if(location.pathname === '/single'&&user&&user.singleType === 1){
      showTopAlert = true;
    }
    if(location.pathname === '/multi'&&user&&user.multiType === 1){
      showTopAlert = true;
    }
    if(location.pathname === '/picture'&&user&&user.picType === 1){
      showTopAlert = true;
    }
    if(location.pathname === '/momo/share'||location.pathname === '/several/share'){
      showTopAlert = false;
    }
    const tipContent = (
        <div>
          <span>上传后的素材将在1个工作日内完成人工审核，请耐心等待。审核通过的素材可在30日内修改。点击查看<Button onClick={this.goToApiDoc}  style={{background:'transparent',border:'none',boxShadow:'unset',color:'#014fe0',fontWeight:'bold'}}>接口文档</Button></span>
        </div>
    );
    return (
      <Layout className={classnames}>
        <Header>
          <NavBar
            collapsed={collapsedLeftSide}
            onCollapseLeftSide={this.onCollapseLeftSide}
            onExpandTopBar={this.onExpandTopBar}
            toggleSidebarHeader={this.toggleSidebarHeader}
            theme={theme.navbar}
            user={user}
            history={history}
            isMobile={isMobile}
            logout={this.logout}
          />
        </Header>
        <Layout>
          <LeftSideBar
                collapsed={collapsedLeftSide}
                leftCollapsedWidth={leftCollapsedWidth}
                showHeader={showSidebarHeader}
                onCollapse={this.onCollapseLeftSide}
                onCollapseAll={this.onCollapseLeftSideAll}
                location={location}
                theme={theme.leftSide}
                flatMenu={flatMenu}
                currentMenu={currentMenu}
                openKeys={openKeys}
                menu={menu}
                user={user}
                isMobile={isMobile}
                badge={badge}
            />
          <Content>
            {theme.layout.indexOf('tabLayout') >= 0 ? (
              <TabsLayout childRoutes={childRoutes} location={location} />
            ) : (
              <Layout className="full-layout">
                <Header>
                  {
                    showTopAlert?<Alert
                                        banner="true"
                                        message=""
                                        description={tipContent}
                                        type="info"
                                        showIcon
                                      />:
                                      <Fragment>
                                        <TopBar
                                            expand={expandTopBar}
                                            // toggleRightSide={this.toggleRightSide}
                                            collapsedRightSide={collapsedRightSide}
                                            // onCollapse={this.onCollapseTopBar}
                                            currentMenu={currentMenu}
                                            location={location}
                                            theme={theme}
                                        />
                                      </Fragment>
                  }
                </Header>
                <Content style={{ overflowX: 'scroll',overflowY:'hidden' }}>
                  <SwitchTransition>
                    <CSSTransition
                      key={location.pathname}
                      classNames="fade"
                      timeout={500}
                    >
                      <Layout className="full-layout">
                        <Content className="router-page">
                          <Switch location={location}>{childRoutes}</Switch>
                        </Content>
                      </Layout>
                    </CSSTransition>
                  </SwitchTransition>
                </Content>
              </Layout>
            )}
          </Content>
          <RightSideBar
            collapsed={collapsedRightSide}
            isMobile={isMobile}
            onCollapse={this.toggleRightSide}
          />
        </Layout>
        {/*<SkinToolbox onChangeTheme={this.onChangeTheme} theme={theme} />*/}
      </Layout>
    );
  }
}
