import cookie from "react-cookies";
const key = 'future-face'

// 用户登录，保存cookie
const save = (obj,expires) => {
    return cookie.save(key,obj,{maxAge: expires});
};
// 获取当前用户cookie
const load = () => {
    return cookie.load(key);
};
const remove = () =>{
    return cookie.remove(key);
}
export  default {
    save,
    load,
    remove
}
// async function userLogout(user) {
//     return $$.post('/logout', user);
// }