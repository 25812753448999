import React from 'react';
import PageLoading from 'components/Loading/PageLoading';
import { message,Modal } from '@wii-fe/wau';
import $$ from 'cmn-utils';
import 'src/models/global.less';
import cookie from "./utils/cookie";
import { createHashHistory } from 'history';
const history = createHashHistory();
// 系统通知, 定义使用什么风格的通知，normal或antdNotice
// const notice = normal;
let showTimeOutModal = false;
const notice = message;
const modal = {
  confirm:()=>{
    const modal = Modal.confirm({
      title: '登录账号发生变更',
      bodyStyle:{display:'flex',alignItems:'center',justifyContent:'center'},
      icon: null,
      centered:true,
      className:'modal-confirm',
      content: <div><div>当前页面下有其他账号在不同设备、浏览器或选项卡发生了登录操作。</div><div>请刷新以同步至最新登录账号，或切换其他账号登录。</div></div>,
      okText: '刷新页面',
      okType:'primary',
      destroyOnClose: true,
      cancelText: <span style={{color:'#014fe0'}}>登录其他账号</span>,
      onCancel:(e)=>{
        showTimeOutModal = false;
        history.push("/sign/login");
        modal.destroy();
        // window.location.href = window.location.origin;
      },
      onOk:(e)=>{
        showTimeOutModal = false
      }
    });
  }
}

const getCurrentUrl= () =>{
  const urlParams = new URL(window.location.href);
  const pathname = urlParams?.hash;
  return pathname.replace("#","");
}

/**
 * 应用配置 如请求格式，反回格式，异常处理方式，分页格式等
 */

export default {
  /**
   * HTML的title模板
   */
  htmlTitle: '未来应用人脸融合 管理后台',

  /**
   * 系统通知
   */
  notice,

  // 异步请求配置
  request: {
    prefix: '/face-admin/face/manage',
    // prefix: '/api',

    // 每次请求头部都会带着这些参数
    withHeaders: () => ({
      token: cookie.load("future-face")?cookie.load("future-face").token:'',
      route: getCurrentUrl(),
    }),

    /**
     * 因为modelEnhance需要知道服务器反回的数据，
     * 什么样的是成功，什么样的是失败，如
     * {status: true, data: ...} // 代表成功
     * {status: false, message: ...} // 代表失败
     * 实际中应该通过服务端反回的response中的
     * 成功失败标识来进行区分
     */
    afterResponse: response => {
      const { code,message,data } = response;
      if(code === 200){
        if(data){
          const { token,expires,accountId,accountType,timeout,singleTip,jumpUrl,singleLiveTime,multiTip,multiLiveTime,picTip,picLiveTime } = data;
          if(timeout){
            $$.setStore("expire",{timeout});
            history.push('/sign/timeout');
            return ;
          }
          if(token){
            cookie.save({token,accountId,accountType},expires);
          }
          if(singleTip||multiTip||picTip){
            $$.setStore("expire",{singleTip,singleLiveTime,multiTip,multiLiveTime,picTip,picLiveTime,jumpUrl});
            history.push('/sign/timeout');
            return ;
          }
          return data;
        }
        return ;
      }
      if(code === 1000111){
        return response;
      }
      //登陆失效弹窗
      // if(code === 401 &&window.location.hash!=="#/sign/login"){
      if(code === 401 ){
        if(window.location.hash!=="#/sign/login"){
          history.push('/sign/login');
          // if(!showTimeOutModal){
            // showTimeOutModal = true;
            // modal.confirm();
          // }
        }
        return response;
      }
      if(code === 404 || code === 403){
        // if()
        history.push('/403');
        return ;
      }
      if(code===1000119){
        window.location.href="https://facefusion-api.huanlian.cn/face-api/doc.html";
        return
      }
      throw new Error(message);
    },
    errorHandle: err => {
      if (err.name === 'RequestError') {
        // console.log(err.code);
        notice.error(err.text || err.message, 2).then(r =>{console.log(r)});
      }
      return err;
    }
  },

  // 全局异常
  exception: {
    global: (err, dispatch) => {
      const errName = err.name;
      // RequestError为拦截请求异常
      if (errName === 'RequestError') {
        // notice.error(err.message);
        console.error(err);
      } else {
        console.error(err);
      }
    },
  },

  // 分页助手
  pageHelper: {
    // 格式化要发送到后端的数据
    requestFormat: pageInfo => {
      const { pageNum, pageSize, filters, sorts,words, type=-1, status=-1,accountId,sortType,sortStyle,singleType,multiType,picType,picVersion,orderType,selectStatus } = pageInfo;
      // return {
      //   currentPage: pageNum,
      //   showCount: pageSize,
      //   sortMap: sorts,
      //   paramMap: filters
      // };
      return {
        pageNum: pageNum,
        pageSize: pageSize,
        sortMap: sorts,
        paramMap: filters,
        words: words,
        type: type,
        status: status,
        accountId: accountId,
        sortType: sortType,
        sortStyle: sortStyle,
        singleType:singleType,
        multiType:multiType,
        picType:picType,
        picVersion:picVersion,
        orderType:orderType,
        selectStatus:selectStatus
      };
    },

    // 格式化从后端反回的数据
    responseFormat: resp => {
      const {
        currentPage,
        showCount,
        totalResult,
        dataList,
        totalPage,
        dealCount,
        videoDealCount,
        picDealCount,
        timeOutTips
      } = resp.data;
      return {
        pageNum: currentPage,
        pageSize: showCount,
        total: totalResult,
        totalPages: totalPage,
        list: dataList,
        dealCount: dealCount,
        timeOutTips: timeOutTips,
        videoDealCount:videoDealCount,
        picDealCount:picDealCount,
      };
    }
  },

  // 路由加载效果
  router: {
    loading: <PageLoading loading />
  },

  /**
   * 模拟数据时包装反回数据
   * 因为，后端反回数据时一般都会在外边包装一层状态信息
   * 如成功时：
   * {
   *   status: true,
   *   data: responseData
   * }
   * 或出错时：
   * {
   *   status: false,
   *   code: 500,
   *   message: '用户名或密码错误'
   * }
   * 这里就是配置这两个函数，为了我们模拟数据时可以少写几行代码的 orz...
   */
  // mock: {
  //   toSuccess: response => ({
  //     status: true,
  //     data: response
  //   }),
  //
  //   toError: message => ({
  //     status: false,
  //     message: message
  //   })
  // }
};
