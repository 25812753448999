import './styles/user.less';
import React from 'react';
import { connect } from 'dva';
import { Layout } from '@wii-fe/wau';
import { router } from 'dva';
const { Switch } = router;
const { Content } = Layout;

@connect()
export default class UserLayout extends React.PureComponent {
  render() {
    const {routerData} = this.props;
    const {childRoutes} = routerData;

    return (
      <Layout className="full-layout user-layout fixed">
        <Content>
          <Switch>{childRoutes}</Switch>
        </Content>
      </Layout>
    );
  }
}
