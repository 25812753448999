import React, { PureComponent } from 'react';
import Icon from '../Icon';
import { Popover, Badge, Button } from '@wii-fe/wau';
import { router,routerRedux } from 'dva';
import cx from 'classnames';
import './style/index.less';
import $$ from 'cmn-utils';
// import logoImg from 'assets/images/logo.png';
import SearchBox from './SearchBox';
const { Link } = router;

/**
 * 其本本局头部区域
 */
class NavBar extends PureComponent {
  state = {
    openSearchBox: false
  };

  static defaultProps = {
    fixed: true,
    theme: '' //'bg-dark',
  };

  toggleFullScreen() {
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  }

  onCloseSearchBox = () => {
    this.setState({
      openSearchBox: false
    });
  };

  onOpenSearchBox = () => {
    this.setState({
      openSearchBox: true
    });
  };
  logout = (history) =>{
    $$.clearStore();
    history.replace('/sign/login');
  };
  render() {
    const { openSearchBox } = this.state;
    const {
      fixed,
      theme,
      onCollapseLeftSide,
      collapsed,
      onExpandTopBar,
      toggleSidebarHeader,
      user,
      isMobile,
      history,
      logout
    } = this.props;

    const classnames = cx('navbar', {
      'navbar-fixed-top': !!fixed,
      'navbar-sm': isMobile ? true : collapsed,
      ['bg-' + theme]: !!theme
    });

    return (
      <header className={classnames}>
        <div className="navbar-branding">
          <span className="navbar-brand">
            {/*<img src={logoImg} alt="logo" />*/}
            <b>未来应用人脸融合管理后台</b>
            {/*Admin*/}
          </span>
          {/*<span className="toggle_sidemenu_l" onClick={onCollapseLeftSide}>*/}
          {/*  <Icon type="lines" />*/}
          {/*</span>*/}
        </div>
        {/*<ul className="nav navbar-nav navbar-left clearfix">*/}
        {/*  {collapsed || isMobile ? null : (*/}
        {/*    <li>*/}
        {/*      <a className="sidebar-menu-toggle" onClick={toggleSidebarHeader}>*/}
        {/*        <Icon type="ruby" />*/}
        {/*      </a>*/}
        {/*    </li>*/}
        {/*  )}*/}
        {/*  <li>*/}
        {/*    <a onClick={onExpandTopBar}>*/}
        {/*      <Icon type="wand" />*/}
        {/*    </a>*/}
        {/*  </li>*/}
        {/*  {isMobile ? (*/}
        {/*    <li className="mini-search" onClick={this.onOpenSearchBox}>*/}
        {/*      <a>*/}
        {/*        <Icon type="search" antd />*/}
        {/*      </a>*/}
        {/*    </li>*/}
        {/*  ) : (*/}
        {/*    <li onClick={this.toggleFullScreen}>*/}
        {/*      <a className="request-fullscreen">*/}
        {/*        <Icon type="screen-full" />*/}
        {/*      </a>*/}
        {/*    </li>*/}
        {/*  )}*/}
        {/*</ul>*/}
        {/*{isMobile ? null : (*/}
        {/*  <form className="navbar-form navbar-search clearfix">*/}
        {/*    <div className="form-group">*/}
        {/*      <input*/}
        {/*        type="text"*/}
        {/*        className="form-control"*/}
        {/*        placeholder="全文检索"*/}
        {/*        onClick={this.onOpenSearchBox}*/}
        {/*      />*/}
        {/*    </div>*/}
        {/*  </form>*/}
        {/*)}*/}
        <ul className="nav navbar-nav navbar-right clearfix">
          {/*<li>*/}
          {/*  <a href="https://github.com/LANIF-UI/dva-boot-admin">*/}
          {/*    <Icon type="GithubOutlined" antd />*/}
          {/*  </a>*/}
          {/*</li>*/}
          {/*<li className="dropdown">*/}
          {/*  <Popover*/}
          {/*    placement="bottomRight"*/}
          {/*    title={'通知'}*/}
          {/*    overlayClassName={cx('navbar-popup', { [theme]: !!theme })}*/}
          {/*    content={''}*/}
          {/*    trigger="click"*/}
          {/*  >*/}
          {/*    <a className="dropdown-toggle">*/}
          {/*      <Icon type="radio-tower" />*/}
          {/*    </a>*/}
          {/*  </Popover>*/}
          {/*</li>*/}
          <li className="dropdown" style={{height:'60px',display:'inline-block',fontSize:'14px',fontWeight:'bold'}}>
            <span className="dropdown-toggle" style={{lineHeight:'58px'}}>
              账户ID：{user?user.accountId:null}
            </span>
            <span  style={{marginLeft:'12px',cursor: 'pointer'}}>
              <Button onClick={()=>logout(history)} style={{color:'#FFF',background:'transparent',border: 0,fontWeight:'bold'}}>
                退出登录
              </Button>
            </span>
            {/*<Popover*/}
            {/*  placement="bottomRight"*/}
            {/*  title={`WELCOME ${user.userName}`}*/}
            {/*  overlayClassName={cx('navbar-popup', { [theme]: !!theme })}*/}
            {/*  content={<UserDropDown />}*/}
            {/*  trigger="click"*/}
            {/*>*/}
            {/*  <a className="dropdown-toggle">*/}
            {/*    <Badge dot>*/}
            {/*      <Avatar src={require('assets/images/avatar.jpg')}>*/}
            {/*        {user.userName}*/}
            {/*      </Avatar>*/}
            {/*    </Badge>*/}
            {/*  </a>*/}
            {/*</Popover>*/}
          </li>
        </ul>
        {/*<SearchBox visible={openSearchBox} onClose={this.onCloseSearchBox} />*/}
      </header>
    );
  }
}

const UserDropDown = props => (
  <ul className="dropdown-menu list-group dropdown-persist">
    <li className="list-group-item">
      <a className="animated animated-short fadeInUp">
        <Icon type="mail" /> 信息
        <Badge count={5} className="label" />
      </a>
    </li>
    <li className="list-group-item">
      <a className="animated animated-short fadeInUp">
        <Icon type="users" /> 好友
        <Badge count={6} className="label" />
      </a>
    </li>
    <li className="list-group-item">
      <a className="animated animated-short fadeInUp">
        <Icon type="gear" /> 帐户设置
      </a>
    </li>
    <li className="list-group-item">
      <a className="animated animated-short fadeInUp">
        <Icon type="ring" /> 通知
      </a>
    </li>
    <li className="list-group-item dropdown-footer">
      <Link to="/sign/login">
        <Icon type="poweroff" /> 退出
      </Link>
    </li>
  </ul>
);

export default NavBar;
